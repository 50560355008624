<template>
  <common-form
    name="contact-info"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ translate('Welcome!', { gender }) }}</p>
        </h2>

        <div class="secondary--text">
          {{ translate(`
          Please accurately complete the information on the following pages.
          This will save you time at your next appointment, and allow you to
          avoid completing additional forms later.
          `) }}<br>
          {{ translate(`
          All information you input here is securely stored using the latest
          encryption technology that meets or exceeds HIPAA medical privacy
          standards, and you can access and update your information anytime.
          `) }}<br>
          {{ translate(`
          We take your privacy seriously and we never share your information
          without your consent.
          `) }}<br>
          {{ translate(`We're excited to see you at your next visit!`) }}
        </div>
      </template>

      <v-row class="flex-grow-0">
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="info.firstname"
            :label="`1. ${firstnameLabel}*`"
            dense
            :rules="[$validators.isValid()]"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="info.middlename"
            :label="`2. ${middlenameLabel}`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="info.lastname"
            :label="`3. ${lastnameLabel}*`"
            dense
            :rules="[$validators.isValid()]"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
        >
          <v-text-field
            v-model="info.preferredName"
            :label="`4. ${prefferedNameLabel}`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="info.email"
            :label="`5. ${emailLabel}`"
            dense
            :rules="[
              $validators.isEmail(),
              !emailDuplication || 'This email is already in use'
            ]"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.homePhone"
            v-mask="'(###) ###-####'"
            :label="`6. ${homePhoneLabel}:`"
            autocomplete="off"
            :rules="[!info.homePhone ||
              $validators.isValidPhone()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.workPhone"
            v-mask="'(###) ###-####'"
            :label="`7. ${workPhoneLabel}:`"
            autocomplete="off"
            :rules="[!info.workPhone ||
              $validators.isValidPhone()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.cellPhone"
            v-mask="'(###) ###-####'"
            :label="`8. ${cellPhoneLabel}:`"
            autocomplete="off"
            :rules="[!info.cellPhone ||
              $validators.isValidPhone()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="info.add1"
            :label="`9. ${add1Label}:`"
            :rules="[$validators.isValid()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="info.add2"
            :label="`10. ${add2Label}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.city"
            :label="`11. ${cityLabel}:`"
            :rules="[$validators.isValid()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="info.state"
            :label="`12. ${stateLabel}:`"
            :items="states"
            :rules="[$validators.isValid()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="info.zip"
            v-mask="'#####-####'"
            :label="`13. ${zipLabel}:`"
            autocomplete="off"
            :rules="[
              $validators.isValid(),
              $validators.isZipCode()
            ]"
            dense
          />
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { utils, validators, events } from 'ui-common'
import repositories from '@/repositories'
import { useProfile } from '@/use/profile'
import { states, PageContactInfo } from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const useContactInfo = () => {
  const info = ref({
    firstname: '',
    middlename: '',
    lastname: '',
    preferredName: '',
    email: '',
    homePhone: '',
    workPhone: '',
    cellPhone: '',
    add1: '',
    add2: '',
    city: '',
    state: '',
    zip: ''
  })
  const emailDuplication = ref(false)

  const {
    primaryProfile,
    secondaryProfile,

    updatingProfile,
    updateSecondaryProfile
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const feedData = () => {
    const {
      firstname = '',
      middlename = '',
      lastname = '',
      preferredName = '',
      email = '',
      homePhone = '',
      workPhone = '',
      cellPhone = '',
      add1 = '',
      add2 = '',
      city = '',
      state = '',
      zip = ''
    } = secondaryProfile.value

    info.value = {
      firstname,
      middlename,
      lastname,
      preferredName,
      email,
      homePhone: validators.formatPhoneNumber(homePhone),
      workPhone: validators.formatPhoneNumber(workPhone),
      cellPhone: validators.formatPhoneNumber(cellPhone),
      add1,
      add2,
      city,
      state,
      zip
    }
  }

  const submitData = async () => {
    try {
      await updateSecondaryProfile({
        info: {
          ...info.value,
          homePhone: validators.formatPhoneNumber(info.value.homePhone, false),
          workPhone: validators.formatPhoneNumber(info.value.workPhone, false),
          cellPhone: validators.formatPhoneNumber(info.value.cellPhone, false),
          lastRegSect: PageContactInfo
        }
      })
      await getCompletedSections()
      utils.navigate('/register')
    } catch (err) {}
  }

  watch(() => info.value.email, async (email) => {
    if (!email) { emailDuplication.value = false }

    const { patientid } = primaryProfile.value
    const res = await repositories.patients.getEmailDuplication({
      email,
      patientId: patientid
    })
    const { hasDuplicates } = res.data
    emailDuplication.value = hasDuplicates
  })

  return {
    info,
    feedData,
    submitData,
    emailDuplication,
    primaryProfile,
    updatingProfile
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const {
      info,
      feedData,
      submitData,
      primaryProfile,
      updatingProfile,
      emailDuplication
    } = useContactInfo()

    onMounted(() => {
      feedData()
      root.$nextTick(() => {
        events.emit('form.reset.contact-info')
      })
    })

    return {
      info,
      states,
      submitData,
      updatingProfile,
      emailDuplication,

      gender: computed(() => primaryProfile.value &&
        (primaryProfile.value.gender.toLowerCase() === 'female')
      ),
      firstnameLabel: computed(() =>
        root.$i18n.t('First Name')
      ),
      middlenameLabel: computed(() =>
        root.$i18n.t('Middle Init')
      ),
      lastnameLabel: computed(() =>
        root.$i18n.t('Last Name')
      ),
      prefferedNameLabel: computed(() =>
        root.$i18n.t('Preferred Name')
      ),
      emailLabel: computed(() =>
        root.$i18n.t('Email')
      ),
      homePhoneLabel: computed(() =>
        root.$i18n.t('Home Phone')
      ),
      workPhoneLabel: computed(() =>
        root.$i18n.t('Work Phone')
      ),
      cellPhoneLabel: computed(() =>
        root.$i18n.t('Cell Phone')
      ),
      add1Label: computed(() =>
        root.$i18n.t('Address1')
      ),
      add2Label: computed(() =>
        root.$i18n.t('Address2')
      ),
      cityLabel: computed(() =>
        root.$i18n.t('City')
      ),
      stateLabel: computed(() =>
        root.$i18n.t('State')
      ),
      zipLabel: computed(() =>
        root.$i18n.t('Zip')
      )
    }
  }
}
</script>
